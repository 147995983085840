import { configureStore } from '@reduxjs/toolkit';

import counterReducer from '@/features/counter/counter-slice';
import quizReducer from '@/features/quiz/quiz-client-state-slice';
import { apiSlice } from '@/features/quiz/quiz-api-slice';

const store = configureStore({
  reducer: {
    counter: counterReducer,
    quiz: quizReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(apiSlice.middleware);
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
