module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Sharper Edge Training Solutions Ltd.","short_name":"Sharper Edge Solutions","description":"Purchase exam preparation guides for various Canadian pilot licences and ratings, including the PPL, CPL, ATPL, INRAT and AIRAF exams, and the Multi-Engine Study Guide from our online store.","lang":"en","start_url":"/","icon":"src/images/favicon.svg","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4c55e8687b375548cd6c4ead7dc1222b"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1550},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-W02FRC4MDQ"],"gtagConfig":{}},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
