import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

/* Slice to grab all quizzes from api/db */

export interface Quiz {
  id: number;
  title?: string;
  description?: string;
  tags?: string[];
  questions: {
    id: number;
    text: string;
    tags: string[];
    answers: {
      text: string;
      reason: string;
      isCorrect: boolean;
    }[];
  }[];
  maxScore: number;
}

export type QuizCollection = {
  quizzes: Quiz[];
};

export const apiSlice = createApi({
  reducerPath: `quizApi`,
  baseQuery: fetchBaseQuery({
    baseUrl: `https://functions.sharperedgesolutions.com/`,
  }),
  endpoints(builder) {
    return {
      fetchQuizzes: builder.query<QuizCollection, void>({
        query: () => `setsl-com-quiz-v2`,
      }),
    };
  },
});

export const { useFetchQuizzesQuery } = apiSlice;
