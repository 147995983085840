import { Provider } from 'react-redux';

import store from '@/state/store';

const wrapWithProvider = ({ element }: any) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  return <Provider store={store}>{element}</Provider>;
};

export default wrapWithProvider;
