import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ClientQuiz {
  quizzes: {
    id: number;
    score: number;
    numberOfAttempts: number;
    isComplete: boolean;
    questions: {
      id: number;
      clientAnswerIndex: number;
      clientAnswerCorrect: boolean | null;
      reason: string;
    }[];
  }[];
}

export type SelectQuizAction = {
  quizId: number;
};

export type SelectAnswerAction = {
  quizId: number;
  questionId: number;
  answerIndex: number;
};

// Tried to pick these props from interface...
type ScoreAction = {
  quizId: number;
  score: number;
};

const initialState: ClientQuiz = {
  quizzes: [],
};

const quizClientStateSlice = createSlice({
  name: `clientQuiz`,
  initialState,
  reducers: {
    quizSelected(state, action: PayloadAction<SelectQuizAction>) {
      const { quizId } = action.payload;
      const theQuiz = state.quizzes.find((quiz) => quiz.id === quizId);

      if (!theQuiz) {
        state.quizzes.push({
          id: quizId,
          score: 0,
          numberOfAttempts: 0,
          isComplete: false,
          questions: [],
        });
      }
    },
    /* payload: {quizId, questionId, answerIndex} */
    quizAnswerSelected(state, action: PayloadAction<SelectAnswerAction>) {
      const { quizId, questionId, answerIndex } = action.payload;
      const theQuiz = state.quizzes.find((quiz) => quiz.id === quizId);
      const theQuestion = theQuiz?.questions.find(
        (question) => question.id === questionId,
      );

      if (theQuestion) {
        theQuestion.clientAnswerIndex = answerIndex;
      } else {
        theQuiz?.questions.push({
          id: questionId,
          clientAnswerIndex: answerIndex,
          clientAnswerCorrect: null,
          reason: ``,
        });
      }
    },
    setAnswerCorrectState(
      state,
      action: PayloadAction<{
        quizId: number;
        questionId: number;
        answerCorrect: boolean;
      }>,
    ) {
      /* TODO: this set of actions is done a lot... */
      const { quizId, questionId, answerCorrect } = action.payload;
      const theQuiz = state.quizzes.find((quiz) => quiz.id === quizId);
      const theQuestion = theQuiz?.questions.find(
        (question) => question.id === questionId,
      );

      if (theQuestion) {
        theQuestion.clientAnswerCorrect = answerCorrect;
      }
    },
    setAnswerStateAfterScoring(
      state,
      action: PayloadAction<{
        quizId: number;
        questionId: number;
        reason: string;
      }>,
    ) {
      const { quizId, questionId, reason } = action.payload;
      const theQuiz = state.quizzes.find((quiz) => quiz.id === quizId);
      const theQuestion = theQuiz?.questions.find(
        (question) => question.id === questionId,
      );

      if (theQuestion) {
        // theQuestion.clientAnswerCorrect = true;
        theQuestion.reason = reason;
      }
    },
    saveQuizScore(state, action: PayloadAction<ScoreAction>) {
      const { quizId, score } = action.payload;
      const theQuiz = state.quizzes.find((quiz) => quiz.id === quizId);

      if (theQuiz) {
        theQuiz.score = score;
        theQuiz.numberOfAttempts++;
        // Make sure to check this state in other reducers...
        theQuiz.isComplete = true;
      }
    },
    endQuizAttempt(state, action: PayloadAction<SelectQuizAction>) {
      const { quizId } = action.payload;
      const theQuiz = state.quizzes.find((quiz) => quiz.id === quizId);

      if (theQuiz) {
        theQuiz.isComplete = true;
      }
    },
    startQuizAttempt(state, action: PayloadAction<SelectQuizAction>) {
      const { quizId } = action.payload;
      const theQuiz = state.quizzes.find((quiz) => quiz.id === quizId);

      if (theQuiz) {
        theQuiz.isComplete = false;
        theQuiz.questions = [];
      }
    },
  },
});

export const {
  quizSelected,
  quizAnswerSelected,
  setAnswerCorrectState,
  setAnswerStateAfterScoring,
  saveQuizScore,
  endQuizAttempt,
  startQuizAttempt,
} = quizClientStateSlice.actions;

export default quizClientStateSlice.reducer;
